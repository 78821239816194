import React from 'react';
import { useHistory } from 'react-router-dom';
import { Container, Button } from '@material-ui/core';
import { makeStyles, createStyles } from '@material-ui/core/styles';

import applyCompleteMark from '../../static/images/applyCompleteMark.png';
import bitlock_lite_logo from '../../static/images/bitlock_lite_logo.png';
import logo_mechatto from '../../static/images/logo_mechatto.png';
import bitlock_image from '../../static/images/bitlock_image.png';
import bitlock_app from '../../static/images/bitlock_app.png';
import homehub_app from '../../static/images/homehub-logo.png';
import { SumamoruInstallAppType } from '../../modules/property/types';

const useStyles = makeStyles(() =>
  createStyles({
    background: {
      background: '#03A9F4',
      minHeight: '100vh',
      textAlign: 'center'
    },
    heading: {
      color: '#fff',
      fontSize: '16px',
      paddingTop: 20,
      margin: 0,
      letterSpacing: '0.08em'
    },
    image: {
      marginTop: 40,
      width: 90
    },
    applyHeading: {
      color: 'var(--color-white)',
      fontSize: 20,
      fontWeight: 300,
      fontStyle: 'normal',
      letterSpacing: '0.5px',
      margin: '24px 0 0'
    },
    description: {
      fontSize: 14,
      lineHeight: '20px',
      color: 'var(--color-white)',
      margin: '16px 0 0',
      fontWeight: 'normal',
      fontStyle: 'normal'
    },
    container: {
      margin: 0,
      padding: '0 24px 40px',
      '@media (min-width:768px)': {
        backgroundColor: '#F7FCFF',
        padding: 0,
        maxWidth: '100%',
        paddingBottom: '100px'
      }
    },
    hr: {
      opacity: 0.3,
      margin: '48px 0 0',
      width: 'calc(100% - 2px)'
    },
    serviceDescHeading: {
      color: 'var(--color-white)',
      fontSize: 16,
      letterSpacing: '0.5px',
      fontWeight: 600,
      marginTop: 32,
      '@media (min-width:768px)': {
        fontWeight: 300,
        fontSize: 20,
        color: 'var(--color-text)'
      }
    },
    whiteContainer: {
      marginTop: 16,
      paddingTop: 20,
      background: 'var(--color-white)',
      borderRadius: 10,
      width: '100%',
      padding: '8px 0 16px',
      '@media (min-width:768px)': {
        width: 640,
        margin: '16px auto',
        boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.16)'
      }
    },
    serviceDescription: {
      color: 'var(--color-text)',
      fontSize: 14,
      fontStyle: 'normal',
      fontWeight: 300,
      textAlign: 'left',
      lineHeight: '140%',
      padding: '0 16px',
      margin: '16px 0 0'
    },
    usage: {
      margin: '2px 0 0',
      fontSize: 12,
      color: 'var(--color-gray-3)'
    },
    serviceTitle: {
      fontSize: 16,
      fontWeight: 500,
      margin: '0',
      color: 'var(--color-text)'
    },
    button: {
      margin: '16px 16px 0',
      width: 'calc(100% - 32px)',
      height: 48,
      padding: '0',
      fontSize: 14,
      lineHeight: '32px',
      color: 'white',
      textTransform: 'none',
      background: 'var(--color-bitkey)',
      '&.MuiButton-root:hover': {
        backgroundColor: 'var(--color-bitkey)'
      },
      '& img': {
        width: 24,
        height: 24,
        marginRight: 8
      }
    },
    appLinkAreaWrapper: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      flexDirection: 'column',
      marginTop: 16
    },
    homehubLogo: {
      width: 140
    },
    appLinkWrapper: {
      marginTop: 8,
      display: 'flex',
      alignItems: 'center'
    },
    appleAppLink: {
      display: 'inline-block',
      overflow: 'hidden',
      background:
        'url(https://linkmaker.itunes.apple.com/ja-jp/badge-lrg.svg?releaseDate=2019-05-16&kind=iossoftware&bubble=ios_apps) no-repeat',
      width: 135,
      height: 40
    },
    googleAppLink: {
      marginLeft: 8
    },
    googleAppImg: {
      width: 154
    }
  })
);

type PropsFromParent = {
  isAlready?: boolean;
  hasElectricContract?: boolean;
  sumamoruInstallAppTypes?: Array<string>;
};

const ApplyCompleteScreen: React.FC<PropsFromParent> = props => {
  const styles = useStyles();
  const history = useHistory();
  const title = props.hasElectricContract ? 'スマモル賃貸プラン申し込み' : 'スマモル賃貸サービス申し込み';
  const checkAlreadyRegistered = history.location.state ? history.location.state.isAlreadyRegistered : false;

  const isGuideHomehub: boolean = props.sumamoruInstallAppTypes
    ? props.sumamoruInstallAppTypes.some(type => type === SumamoruInstallAppType.homehub)
    : false;
  const appName = isGuideHomehub ? 'homehub' : 'bitlock';
  const deviceDescription = isGuideHomehub
    ? '入居予定のお部屋には、スマートロックが設置されています。「homehub」アプリを使用して、お部屋のカギを解施錠することが可能です。'
    : '入居予定のお部屋には、bitlock LITEが設置されています。「bitlock」アプリ(またはbitbutton)を使用して、お部屋のカギを解施錠することが可能です。';

  return (
    <div className={styles.background}>
      <Container>
        <h1 className={styles.heading}>{title}</h1>
        <img className={styles.image} src={applyCompleteMark} alt={'申し込み完了'} />
        <h1 className={styles.applyHeading}>
          {props.isAlready || checkAlreadyRegistered
            ? 'すでにお申し込みが完了しています。'
            : 'お申し込みが完了しました！'}
        </h1>
        <p className={styles.description}>
          {props.isAlready || checkAlreadyRegistered
            ? '現在もサービスをご利用いただけていない場合は'
            : 'ご登録のメールアドレス宛に'}
          <br />
          {props.isAlready || checkAlreadyRegistered
            ? 'お手数ですが、サポートまでご連絡ください。'
            : 'お申し込み内容を送信しています。'}
        </p>
      </Container>
      <Container className={styles.container}>
        <hr className={styles.hr} />
        <h2 className={styles.serviceDescHeading}>各サービスのご利用方法</h2>
        <div className={styles.whiteContainer}>
          {isGuideHomehub ? 'homehubアプリ' : <img width={104} src={bitlock_lite_logo} alt={'bitlock_lite_logo'} />}
          <p className={styles.usage}>のご利用方法</p>
          <img width={'100%'} style={{ marginTop: 16 }} src={bitlock_image} alt={'bitlock_image'} />
          <p className={styles.serviceDescription}>
            {deviceDescription}
            <br />
            アプリは下のボタンからダウンロードしてください。
            <br />
            必ず、当サイトで認証したBitkeyアカウントでログインし、ご利用下さい。
          </p>

          {isGuideHomehub ? (
            <div className={styles.appLinkAreaWrapper}>
              <img alt={'homehub'} src={homehub_app} className={styles.homehubLogo} />
              <div className={styles.appLinkWrapper}>
                <a href={'https://apps.apple.com/jp/app/homehub/id1545859399'} className={styles.appleAppLink} />
                <a
                  href="https://play.google.com/store/apps/details?id=app.jp.co.bitkey.homehub&hl=ja&gl=US"
                  className={styles.googleAppLink}
                >
                  <img
                    alt="Google Play で手に入れよう"
                    src="https://play.google.com/intl/ja/badges/static/images/badges/ja_badge_web_generic.png"
                    className={styles.googleAppImg}
                  />
                </a>
              </div>
            </div>
          ) : (
            <Button className={styles.button} size="large" href={'https://dl.bitlock.jp/r/app/'}>
              <img alt={'bitley'} src={bitlock_app} />
              <span>bitlockアプリをダウンロード</span>
            </Button>
          )}
          {!isGuideHomehub && (
            <Button
              className={styles.button}
              size="large"
              href={'https://www.youtube.com/playlist?list=PLkgOkqDYDxsO3ceogBQAXRa-fDlQCIgqp'}
              target="_blank"
            >
              <span>bitlockの使い方動画を見る</span>
            </Button>
          )}
        </div>
        <div className={styles.whiteContainer}>
          {/* <img width={120} src={logo_mechatto} alt={'logo_mechatto'} /> */}
          <h2 className={styles.serviceTitle}>優待・割引サービス</h2>
          <p className={styles.usage}>のご利用方法</p>

          <p className={styles.serviceDescription}>
            {appName + 'アプリ内に優待・割引サービスご利用サイトにアクセスできるURLが表示されます。'}
            <br />
            また、申込後に届くメールでサービスサイトURL、およびIDとパスワードをお知らせします。
          </p>
        </div>
        <div className={styles.whiteContainer}>
          <h2 className={styles.serviceTitle}>駆けつけサービス</h2>
          <p className={styles.usage}>のご利用方法</p>

          <p className={styles.serviceDescription}>
            {'このサイトでの申込後、' +
              appName +
              'アプリ内に駆けつけサービスご利用サイトにアクセスできるURLが表示されます。'}
          </p>
        </div>
      </Container>
    </div>
  );
};

export default ApplyCompleteScreen;
