import React from 'react';
import { Container } from '@material-ui/core';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import { Path } from '../constants/path';

const useStyles = makeStyles(() =>
  createStyles({
    background: {
      background: '#ECEFF1',
      textAlign: 'center'
    },
    container: {
      margin: 0,
      padding: '10px 15px'
    },
    linkTitle: {
      fontSize: '12px',
      lineHeight: '17px',
      color: '#828282',
      margin: '0 12px'
    }
  })
);
interface P {
  privacyPolicy: {
    text: string;
    link: string;
  };
}

const Footer: React.FC<P> = props => {
  const styles = useStyles({});
  return (
    <div className={styles.background}>
      <Container className={styles.container}>
        <Link
          className={styles.linkTitle}
          style={{ textDecoration: 'none' }}
          to={Path.other.sitePolicy}
          target="_blank"
          rel="noopener noreferrer"
        >
          利用規約
        </Link>
        <a
          className={styles.linkTitle}
          style={{ textDecoration: 'none' }}
          href={props.privacyPolicy.link}
          target="_blank"
          rel="noopener noreferrer"
        >
          {props.privacyPolicy.text}
        </a>
        <br />
        <a
          className={styles.linkTitle}
          style={{ textDecoration: 'none' }}
          href="https://bitkey.co.jp/privacy-policy/"
          target="_blank"
          rel="noopener noreferrer"
        >
          bitkeyプライバシーポリシー
        </a>
      </Container>
    </div>
  );
};

export default Footer;
