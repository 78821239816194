import { ContainerElement, ComponentElementCondition } from '../types/common/item-definition-types';
import { ItemValueKey } from './item-value-key';
import * as moment from 'moment-timezone';
import { ConfirmationDisplayType } from '../enums/confirmation/confirmation-display-type';
import { ComponentDescriptionType } from '../enums/common/component-description-type';
import { ValueOriginType } from '../enums/common/value-origin-type';
import { ComponentType } from '../enums/common/component-type';
import { DetailConditionOperator } from '../enums/common/detail-condition-operator';
import { FixedValueKey } from '../types/common/fixed-value-key';
import { BasicItemKeys } from '../enums/item/basic-item-keys';
import { ConditionsResultJudgeRule } from '../enums/common/conditions-result-judge-rule';

export const dateDisplayFormat = 'YYYY年MM月DD日(ddd)';

const useElectronicCondition: Array<ComponentElementCondition> = [
  {
    detailConditions: [
      {
        valueType: ValueOriginType.ApplicationParams,
        valueKey: FixedValueKey.HasElectricContract,
        operator: DetailConditionOperator.Equal,
        targetValues: [
          {
            valueType: ValueOriginType.Fixed,
            valueKey: true
          }
        ]
      }
    ]
  }
];

const notUseElectronicCondition: Array<ComponentElementCondition> = [
  {
    detailConditions: [
      {
        valueType: ValueOriginType.ApplicationParams,
        valueKey: FixedValueKey.HasElectricContract,
        operator: DetailConditionOperator.NotEqual,
        targetValues: [
          {
            valueType: ValueOriginType.Fixed,
            valueKey: true
          }
        ]
      }
    ]
  }
];
const useGasCondition: Array<ComponentElementCondition> = [
  {
    detailConditions: [
      {
        valueType: ValueOriginType.ApplicationParams,
        valueKey: FixedValueKey.HasElectricContract,
        operator: DetailConditionOperator.Equal,
        targetValues: [
          {
            valueType: ValueOriginType.Fixed,
            valueKey: true
          }
        ]
      },
      {
        valueType: ValueOriginType.InputValues,
        valueKey: ItemValueKey.UseGasPlanSelection,
        operator: DetailConditionOperator.NotEqual,
        targetValues: [
          {
            valueType: ValueOriginType.Fixed,
            valueKey: '1'
          }
        ]
      }
    ]
  }
];
const notUseGasCondition: Array<ComponentElementCondition> = [
  {
    detailConditions: [
      {
        valueType: ValueOriginType.InputValues,
        valueKey: ItemValueKey.UseGasPlanSelection,
        operator: DetailConditionOperator.Equal,
        targetValues: [
          {
            valueType: ValueOriginType.Fixed,
            valueKey: '1'
          }
        ]
      }
    ]
  }
];

const notUseBasicGasPlan: Array<ComponentElementCondition> = [
  {
    detailConditions: [
      {
        valueType: ValueOriginType.InputValues,
        valueKey: ItemValueKey.GasBasePlanSelection,
        operator: DetailConditionOperator.Equal,
        targetValues: [
          {
            valueType: ValueOriginType.Fixed,
            valueKey: '1'
          }
        ]
      }
    ]
  }
];

const postCodeList = [];

const canApplyGasRegion: Array<ComponentElementCondition> = [
  {
    detailConditions: [
      {
        valueType: ValueOriginType.ApplicationParams,
        valueKey: BasicItemKeys.PostCode,
        operator: DetailConditionOperator.Some,
        targetValues: postCodeList.map(code => ({
          valueType: ValueOriginType.Fixed,
          valueKey: code
        }))
      }
    ]
  }
];

const canNotApplyGasRegion: Array<ComponentElementCondition> = [
  {
    detailConditions: [
      {
        valueType: ValueOriginType.ApplicationParams,
        valueKey: BasicItemKeys.PostCode,
        operator: DetailConditionOperator.NotEqual,
        targetValues: postCodeList.map(code => ({
          valueType: ValueOriginType.Fixed,
          valueKey: code
        }))
      }
    ]
  }
];

// 申込画面に表示するコンポーネント定義
export const RegisteredInputContainers: Array<ContainerElement> = [
  {
    id: 'SelectBasicPlan',
    title: '西部ガスのガスもセットで申し込みますか？',
    confirmationTitle: 'ガスの申し込み',
    confirmationDisplayType: ConfirmationDisplayType.EmphasizedDisableInput,
    components: [
      {
        id: ItemValueKey.UseGasPlanSelection,
        label: undefined,
        descriptionsBeforeInput: [
          {
            descriptionType: ComponentDescriptionType.Balloon,
            messages: ['ガスと電気のセット契約で、', '電気料金がオトクになります ']
          }
        ],
        inputElement: {
          valueKey: ItemValueKey.UseGasPlanSelection,
          inputType: ComponentType.RadioButtonInput,
          initialValue: '0',
          selectionItems: [
            {
              value: '0',
              label: 'ガスの申込も一緒に行う'
            },
            {
              value: '1',
              label: 'ガスの申込はしない/プロパンガス物件'
            }
          ]
        },
        descriptionsAfterInput: [
          {
            messages: [
              '※プロパンガスの物件の場合は、ガスの開栓は申込みしないを登録してください。',
              '※ガスの受付は、西部ガスの供給エリア (〇〇を除く地区) に限ります。'
            ]
          }
        ]
      }
    ],
    isDisplayCondition: [...useElectronicCondition, ...canApplyGasRegion]
  },
  {
    id: 'SelectBasicPlan',
    title: '西部ガスのガスもセットで申し込みますか？',
    confirmationTitle: 'ガスの申し込み',
    confirmationDisplayType: ConfirmationDisplayType.EmphasizedDisableInput,
    components: [
      {
        id: ItemValueKey.UseGasPlanSelection,
        label: undefined,
        descriptionsBeforeInput: [
          {
            descriptionType: ComponentDescriptionType.Balloon,
            messages: ['ガスと電気のセット契約で、', '電気料金がオトクになります ']
          }
        ],
        inputElement: {
          valueKey: ItemValueKey.UseGasPlanSelection,
          inputType: ComponentType.RadioButtonInput,
          initialValue: '1',
          selectionItems: [
            {
              value: '0',
              label: 'ガスの申込も一緒に行う',
              disabled: true
            },
            {
              value: '1',
              label: 'ガスの申込はしない/プロパンガス物件'
            }
          ]
        },
        descriptionsAfterInput: [
          {
            messages: [
              '※プロパンガスの物件の場合は、ガスの開栓は申込みしないを登録してください。',
              '※ガスの受付は、西部ガスの供給エリア (〇〇を除く地区) に限ります。'
            ]
          }
        ]
      }
    ],
    isDisplayCondition: [...useElectronicCondition, ...canNotApplyGasRegion]
  },
  {
    id: 'MoveInfo',
    title: 'お引越し情報',
    components: [
      {
        id: ItemValueKey.OccupyScheduledDate,
        label: '入居予定日',
        inputElement: {
          valueKey: ItemValueKey.OccupyScheduledDate,
          valueType: ValueOriginType.ApplicationParams,
          inputType: ComponentType.FixedText,
          converter: value =>
            moment(value)
              .tz('Asia/Tokyo')
              .format(dateDisplayFormat)
        }
      },
      {
        id: ItemValueKey.ElectricityStartDate,
        label: '電気：利用開始日',
        inputElement: {
          valueKey: ItemValueKey.ElectricityStartDate,
          inputType: ComponentType.KeyboardDateInput,
          minDateOffset: 2,
          useBusinessDayOffset: true
        },
        descriptionsAfterInput: [
          {
            messages: [
              '※第２営業日（土日祝除く平日）以内に電気のご利用をご希望の場合、西部ガスのお客さまセンターへお電話いただき、「スマモル賃貸プラン」を希望とお伝えください。',
              '連絡先：０５７０－０００－３１２'
            ]
          }
        ],
        isDisplayCondition: useElectronicCondition
      },
      {
        id: ItemValueKey.ElectricityBasePlanSelection,
        label: '電気お申込みプラン',
        inputElement: {
          valueKey: ItemValueKey.ElectricityBasePlanSelection,
          inputType: ComponentType.RadioButtonInput,
          initialValue: '0',
          selectionItems: [
            {
              value: '0',
              label: 'スマモル賃貸プランB'
            },
            {
              value: '1',
              label: 'スマモル賃貸プランAE'
            }
          ]
        },
        isDisplayCondition: useElectronicCondition,
        descriptionsAfterInput: [
          {
            messages: [
              '※スマモル賃貸プランBは東京電力の従量電灯B相当、スマモル賃貸プランAEは、オール電化のお客さま向け料金メニューです。',
              '※ご契約の容量は、お住いになるマンションの契約電流となります。'
            ]
          }
        ]
      },
      {
        id: ItemValueKey.ElectricityOptionPlanSelection,
        label: '電気オプション割引',
        inputElement: {
          valueKey: ItemValueKey.UseGasPlanSelection,
          inputType: ComponentType.RadioButtonInput,
          initialValue: '0',
          readonly: true,
          selectionItems: [
            {
              value: '0',
              label: 'ガスセット割引'
            },
            {
              value: '1',
              label: 'なし',
              notDisplay: true
            }
          ],
          /* ガス利用の場合のみに表示される条件 */
          isDisableCondition: [
            {
              judgeRuleType: ConditionsResultJudgeRule.Some,
              detailConditions: [...notUseGasCondition[0].detailConditions, ...canNotApplyGasRegion[0].detailConditions]
            }
          ]
        },
        isDisplayCondition: useElectronicCondition,
        descriptionsAfterInput: [
          {
            messages: ['※ガスとセットで申し込まれる場合、「ガスセット割」が適用されます。']
          }
        ]
        /* ガス利用の場合のみに表示される条件 */
        // isDisplayCondition: useGasCondition
      },
      {
        id: ItemValueKey.GasStartDate,
        label: 'ガス：開栓希望日・時間帯',
        descriptionsBeforeInput: [
          {
            messages: ['※ガス開栓にはお客様の立ち会いが必要となります。']
          }
        ],
        inputElement: {
          valueKey: ItemValueKey.GasStartDate,
          inputType: ComponentType.KeyboardDateInput,
          minDateOffset: 5,
          useBusinessDayOffset: true
        },
        showOnlyInput: true,
        /* ガス利用の場合のみに表示される条件 */
        isDisplayCondition: useGasCondition
      },
      {
        id: ItemValueKey.GasStartDateTime,
        label: 'ガス:¥n開栓希望日・時間帯',
        inputElements: [
          {
            valueKey: ItemValueKey.GasStartDate,
            inputType: ComponentType.KeyboardDateInput,
            minDateOffset: 5,
            useBusinessDayOffset: true
          },
          {
            valueKey: '¥n',
            inputType: ComponentType.FixedText,
            valueType: ValueOriginType.Fixed
          },
          {
            valueKey: ItemValueKey.GasStartTimeSelection,
            inputType: ComponentType.SelectItemInput,
            selectionItems: [
              {
                value: '0',
                label: 'ＡＭ'
              },
              {
                value: '1',
                label: 'ＰＭ（１３-１５時）'
              },
              {
                value: '2',
                label: 'ＰＭ（１５-１７時）'
              },
              {
                value: '3',
                label: 'ＥＶＥ（１７-１９時）'
              },
              {
                value: '4',
                label: 'ＰＭ（１３-１７時）'
              }
            ]
          }
        ],
        showOnlyConfirmation: true,
        /* ガス利用の場合のみに表示される条件 */
        isDisplayCondition: useGasCondition
      },
      {
        id: ItemValueKey.GasStartTimeSelection,
        inputElement: {
          valueKey: ItemValueKey.GasStartTimeSelection,
          inputType: ComponentType.SelectItemInput,
          initialValue: '0',
          selectionItems: [
            {
              value: '0',
              label: 'ＡＭ'
            },
            {
              value: '1',
              label: 'ＰＭ（１３-１５時）'
            },
            {
              value: '2',
              label: 'ＰＭ（１５-１７時）'
            },
            {
              value: '3',
              label: 'ＥＶＥ（１７-１９時）'
            },
            {
              value: '4',
              label: 'ＰＭ（１３-１７時）'
            }
          ],
          styleOption: {
            marginTop: 0
          }
        },
        descriptionsAfterInput: [
          {
            messages: ['※第５営業日（土日祝除く平日）以内にガスの開栓をご希望の場合、西部ガスに直接ご連絡ください。']
          }
        ],
        showOnlyInput: true,
        /* ガス利用の場合のみに表示される条件 */
        isDisplayCondition: useGasCondition
      },
      {
        id: ItemValueKey.WitnessGasStart,
        label: '開栓の立ち会い者',
        inputElement: {
          valueKey: ItemValueKey.WitnessGasStart,
          inputType: ComponentType.SelectItemInput,
          initialValue: '0',
          selectionItems: [
            {
              value: '0',
              label: '本人'
            },
            {
              value: '1',
              label: '家主'
            },
            {
              value: '2',
              label: '管理人'
            },
            {
              value: '3',
              label: 'その他'
            }
          ]
        },
        /* ガス利用の場合のみに表示される条件 */
        isDisplayCondition: useGasCondition
      },
      {
        id: ItemValueKey.WitnessNameGasStart,
        label: '立ち会い者氏名',
        inputElement: {
          valueKey: ItemValueKey.WitnessNameGasStart,
          inputType: ComponentType.TextFieldInput,
          placeholder: '姓 名'
        },
        isDisplayCondition: [
          {
            detailConditions: [
              {
                valueType: ValueOriginType.InputValues,
                valueKey: ItemValueKey.WitnessGasStart,
                operator: DetailConditionOperator.Some,
                targetValues: [
                  {
                    valueType: ValueOriginType.Fixed,
                    valueKey: '1'
                  },
                  {
                    valueType: ValueOriginType.Fixed,
                    valueKey: '2'
                  },
                  {
                    valueType: ValueOriginType.Fixed,
                    valueKey: '3'
                  }
                ]
              }
            ]
          },
          ...useGasCondition
        ]
      },
      {
        id: ItemValueKey.PhoneNumberForGasStart,
        label: 'ガス開栓日に連絡可能な電話番号',
        inputElement: {
          valueKey: ItemValueKey.PhoneNumberForGasStart,
          inputType: ComponentType.TextFieldInput,
          placeholder: '08012345678',
          inputComponentType: 'tel'
        },
        descriptionsAfterInput: [
          {
            messages: ['※立ち会い者と連絡可能な電話番号を入力ください']
          }
        ],
        /* ガス利用の場合のみに表示される条件 */
        isDisplayCondition: useGasCondition
      },
      {
        id: ItemValueKey.GasBasePlanSelection,
        label: 'ガスお申込みプラン',
        inputElement: {
          valueKey: ItemValueKey.GasBasePlanSelection,
          inputType: ComponentType.RadioButtonInput,
          initialValue: '0',
          selectionItems: [
            {
              value: '0',
              label: 'ベーシックガス'
            },
            {
              value: '1',
              label: 'ゆかぽかガス'
            }
          ]
        },
        descriptionsAfterInput: [
          {
            messages: [
              '※ベーシックガスはガスの一般料金、ゆかぽかガスはガス温水式床暖房をご利用のお客さま向け料金です。'
            ]
          }
        ],
        /* ガス利用の場合のみに表示される条件 */
        isDisplayCondition: useGasCondition
      },
      {
        id: ItemValueKey.GasOptionPlanSelection,
        label: 'ベーシックガスのオプション割引',
        inputElement: {
          valueKey: ItemValueKey.GasBasePlanSelection,
          inputType: ComponentType.RadioButtonInput,
          initialValue: '0',
          readonly: true,
          selectionItems: [
            {
              value: '0',
              label: '電気セット割引'
            },
            {
              value: '1',
              label: 'なし',
              notDisplay: true
            }
          ],
          /* ガス利用の場合のみに表示される条件 */
          isDisableCondition: notUseBasicGasPlan
        },
        descriptionsAfterInput: [
          {
            messages: ['※スマモル賃貸プランとベーシックガスを申し込みの場合、「電気セット割引」が適用されます。']
          }
        ],
        /* ガス利用の場合のみに表示される条件 */
        isDisplayCondition: [
          {
            detailConditions: [
              {
                valueType: ValueOriginType.InputValues,
                valueKey: ItemValueKey.GasBasePlanSelection,
                operator: DetailConditionOperator.NotEqual,
                targetValues: [
                  {
                    valueType: ValueOriginType.Fixed,
                    valueKey: '1'
                  }
                ]
              }
            ]
          },
          ...useGasCondition
        ]
      },
      {
        id: 'gas_option_discount2',
        label: 'ゆかぽかガスのオプション割引',
        descriptionsAfterInput: [
          {
            messages: [
              '※ゆかぽかガスのオプション割引の適用を希望される場合は、本お申込み後、西部ガスのお客さまセンターへご連絡をお願いします。',
              '連絡先：０５７０－０００－３１２'
            ]
          }
        ],
        showOnlyInput: true,
        /* ガス利用の場合のみに表示される条件 */
        isDisplayCondition: [
          {
            detailConditions: [
              {
                valueType: ValueOriginType.InputValues,
                valueKey: ItemValueKey.GasBasePlanSelection,
                operator: DetailConditionOperator.Equal,
                targetValues: [
                  {
                    valueType: ValueOriginType.Fixed,
                    valueKey: '1'
                  }
                ]
              }
            ]
          },
          ...useGasCondition
        ]
      }
      // {
      //   id: 'gas_option_discount2',
      //   label: 'ガスオプション割引2',
      //   inputElement: {
      //     valueKey: 'gas_option_discount2',
      //     inputType: ComponentInputType.RadioButton,
      //     initialValue: '3',
      //     selectionItems: [
      //       {
      //         value: '0',
      //         label: '浴室暖房割'
      //       },
      //       {
      //         value: '1',
      //         label: 'エコ給湯割'
      //       },
      //       {
      //         value: '2',
      //         label: 'ダブル割'
      //       },
      //       {
      //         value: '3',
      //         label: '割引なし'
      //       }
      //     ]
      //   },
      //   descriptionsAfterInput: [
      //     {
      //       descriptionType: ComponentDescriptionType.Normal,
      //       messages: [
      //         '※浴室暖房割はガス浴室暖房乾燥機をご利用のお客様向け、エコ給湯割は家庭用省エネ給湯器をご利用のお客様向け、ダブル割はガス浴室暖房乾燥機と家庭用省エネ給湯器の両方をご利用のお客様向けのオプション割引です。'
      //       ]
      //     }
      //   ],
      //   /* ガス利用の場合のみに表示される条件 */
      //   isDisplayCondition: [
      //     {
      //       detailConditions: [
      //         {
      //           valueType: ComponentInputValueType.InputValues,
      //           valueKey: 'gassPlan',
      //           operator: ComponentConditionOperator.Equal,
      //           targetValues: [
      //             {
      //               valueType: ComponentInputValueType.Fixed,
      //               valueKey: '1'
      //             }
      //           ]
      //         }
      //       ]
      //     },
      //     ...useGasCondition
      //   ]
      // }
    ]
  },
  {
    id: 'CustomerInformation',
    title: 'お客様情報',
    components: [
      {
        id: ItemValueKey.CustomerNamePrefix,
        inputElement: {
          valueKey: ItemValueKey.CustomerNamePrefix,
          inputType: ComponentType.KanaNameAutoCompleteInput
        },
        showOnlyInput: true
      },
      {
        id: ItemValueKey.CustomerName,
        label: '氏名',
        inputElements: [
          {
            valueKey: ItemValueKey.CustomerLastName,
            inputType: ComponentType.TextFieldInput
          },
          {
            valueKey: ItemValueKey.CustomerFirstName,
            inputType: ComponentType.TextFieldInput
          }
        ],
        showOnlyConfirmation: true
      },
      {
        id: ItemValueKey.CustomerNameKana,
        label: '氏名(カナ)',
        inputElements: [
          {
            valueKey: ItemValueKey.CustomerLastNameKana,
            inputType: ComponentType.TextFieldInput
          },
          {
            valueKey: ItemValueKey.CustomerFirstNameKana,
            inputType: ComponentType.TextFieldInput
          }
        ],
        showOnlyConfirmation: true
      },
      {
        id: ItemValueKey.CustomerBirthDay,
        label: '生年月日',
        inputElement: {
          valueKey: ItemValueKey.CustomerBirthDay,
          inputType: ComponentType.SelectDateInput
        }
      },
      {
        id: ItemValueKey.CustomerPhoneNumber,
        label: '電話番号',
        inputElement: {
          valueKey: ItemValueKey.CustomerPhoneNumber,
          inputType: ComponentType.TextFieldInput,
          placeholder: '08012345678',
          inputComponentType: 'tel'
        }
      },
      {
        id: ItemValueKey.CustomerGender,
        label: '性別',
        inputElement: {
          valueKey: ItemValueKey.CustomerGender,
          inputType: ComponentType.SelectItemInput,
          placeholder: '性別を選択',
          initialValue: '',
          selectionItems: [
            {
              value: '1',
              label: '男性'
            },
            {
              value: '2',
              label: '女性'
            },
            {
              value: '3',
              label: '無回答'
            }
          ]
        }
      },
      {
        id: ItemValueKey.CustomerEmail,
        label: 'メールアドレス',
        inputElement: {
          valueKey: ItemValueKey.CustomerEmail,
          inputType: ComponentType.EmailInput
        }
      }
    ]
  },
  {
    id: ItemValueKey.PaymentMethodSelection,
    title: 'お支払い方法',
    confirmationTitle: 'お支払い手続き',
    confirmationDisplayType: ConfirmationDisplayType.EmphasizedDisableInput,
    components: [
      {
        id: 'paymentInfo',
        label: '口座振替・クレジットカードをご選択の場合、西部ガスより申込書を郵送します。',
        confirmationLabel: '',
        descriptionsBeforeInput: [
          {
            messages: ['※お手続きが完了するまでは払込票でのお支払いとなります。']
          }
        ],
        inputElement: {
          valueKey: ItemValueKey.PaymentMethodSelection,
          inputType: ComponentType.RadioButtonInput,
          initialValue: '0',
          selectionItems: [
            {
              value: '0',
              label: '口座振替'
            },
            {
              value: '1',
              label: 'クレジットカード'
            }
          ]
        }
      }
    ],
    isDisplayCondition: useElectronicCondition
  }
];

export const ableGoToNextPageConditionDefinition = {
  needToInput: {
    inputValues: [
      ItemValueKey.ElectricityStartDate,
      ItemValueKey.GasStartDate,
      ItemValueKey.WitnessNameGasStart,
      ItemValueKey.PhoneNumberForGasStart,
      ItemValueKey.CustomerFirstName,
      ItemValueKey.CustomerLastName,
      ItemValueKey.CustomerFirstNameKana,
      ItemValueKey.CustomerLastNameKana,
      ItemValueKey.CustomerPhoneNumber,
      ItemValueKey.CustomerGender,
      ItemValueKey.CustomerEmailInput,
      ItemValueKey.CustomerEmailConfirmation
    ]
  },
  hasNoError: {
    inputValues: [
      ItemValueKey.ElectricityStartDate,
      ItemValueKey.GasStartDate,
      ItemValueKey.PhoneNumberForGasStart,
      ItemValueKey.CustomerFirstName,
      ItemValueKey.CustomerLastName,
      ItemValueKey.CustomerFirstNameKana,
      ItemValueKey.CustomerLastNameKana,
      ItemValueKey.CustomerPhoneNumber,
      ItemValueKey.CustomerEmailInput,
      ItemValueKey.CustomerEmailConfirmation
    ]
  },
  satisfySpecificCondition: [
    {
      detailConditions: [
        {
          valueType: ValueOriginType.InputValues,
          valueKey: ItemValueKey.AgreeThePolicy,
          operator: DetailConditionOperator.Equal,
          targetValues: [
            {
              valueType: ValueOriginType.Fixed,
              valueKey: true
            }
          ]
        }
      ]
    }
  ]
};

type PageDisplayTextType = {
  initialTemplateDisplayPlanLabel: string;
  initialTemplateDisplayPlanName: string;
  initialTemplateNameDescriptions: string[];
  initialTemplateDisplayNameCautions: string[];
  initialTemplateDisplayDetailCautions: DescriptionElement[][];
  initialTemplateSupplements: DescriptionElement[][];
};

export const pageDisplayText: PageDisplayTextType = {
  initialTemplateDisplayPlanLabel: '',
  initialTemplateDisplayPlanName: '',
  initialTemplateNameDescriptions: [
    'ご入居予定の物件には、西部ガスの電気「スマモル賃貸サービス」が標準採用されています。'
  ],
  initialTemplateDisplayNameCautions: ['スマモルサービスに申し込まない場合は、物件の管理会社へご連絡ください。'],
  initialTemplateDisplayDetailCautions: [
    [
      {
        text: 'ご入居予定の物件には、西部ガスの電気「スマモル賃貸サービス」が標準採用されています。',
        type: ComponentDescriptionType.Normal
      }
    ],
    [
      {
        text: 'スマモルサービスに申し込まない場合は、物件の管理会社へご連絡ください。',
        type: ComponentDescriptionType.Caution
      }
    ]
  ],
  initialTemplateSupplements: [
    [
      {
        text: '別途、郵送されるパンフレットでご説明しているサービスは',
        type: ComponentDescriptionType.Normal
      },
      {
        text: 'こちら',
        type: ComponentDescriptionType.InternalLink,
        path: '/static/sumamoruchintai.pdf'
      },
      {
        text: 'からご確認いただけます。',
        type: ComponentDescriptionType.Normal
      }
    ]
  ]
};

export const getFooterOptions = {
  privacyPolicy: {
    text: '西部ガスプライバシーポリシー',
    link: 'https://www.saibugas.co.jp/policy/kojin_tori.htm'
  }
};

export const baseFormText = {
  useInformationConfirmation: [
    '上記でご入力いただいた情報は、「優待・割引サービス」「駆けつけサービス」で利用されます。また、お客さまが入居契約した物件の管理会社もご入力いただいた情報を利用します。'
  ],
  needToConfirmContent: [
    [
      {
        text: '駆けつけサービス契約約款',
        type: ComponentDescriptionType.InternalLink,
        path: '/static/security_service_term.pdf'
      },
      {
        text: '・',
        type: ComponentDescriptionType.Normal
      },
      {
        text: '優待・割引サービス利用規約',
        type: ComponentDescriptionType.InternalLink,
        path: '/static/benefit_one_term.pdf'
      },
      {
        text: '・',
        type: ComponentDescriptionType.Normal
      },
      {
        text: 'プライバシーポリシー',
        type: ComponentDescriptionType.InternalLink,
        link: 'https://www.saibugas.co.jp/policy/kojin_tori.htm'
      },
      {
        text: 'にご同意の上、申込内容のご確認へお進みください。',
        type: ComponentDescriptionType.Normal
      }
    ]
  ]
};

export type DescriptionElement = {
  text: string;
  type: ComponentDescriptionType;
  link?: string;
  path?: string;
};

export const getImportantConfirmationText = (
  env: 'develop' | 'staging' | 'production'
): {
  electronic: DescriptionElement[][];
  gas?: DescriptionElement[][];
  service?: DescriptionElement[][];
  company?: DescriptionElement[][];
} => {
  return {
    electronic: [
      [
        {
          text: '電気需給契約に関する重要事項説明',
          type: ComponentDescriptionType.Title
        }
      ],
      [
        {
          text:
            'お客さまが、西部ガス株式会社（以下「当社」といいます。）に電気使用の申し込みをしていただくにあたり、当社が電気事業法に基づき説明し、お客さまにご確認いただきたい主要な供給条件は以下のとおりです。なお、電気の供給及び使用に関する契約（以下「電気需給契約」といいます。）の詳細は、電気基本契約要綱及び電気個別要綱（以下「要綱等」といいます。）に定めています。当社は、電気事業法第2条の13第2項に基づく書面の交付（契約締結前書面）及び同法第2条の14第1項に基づく書面の交付（契約締結後書面）について、書面でお知らせする事項を除いては、書面交付に代えて、要綱等を当社のホームページに掲載する方法によりこれを提供いたします。',
          type: ComponentDescriptionType.Normal
        }
      ],
      [
        {
          text: '',
          type: ComponentDescriptionType.Normal
        }
      ],
      [
        {
          text: '１．電気使用の申し込み、電気需給契約の成立及び契約期間',
          type: ComponentDescriptionType.Normal
        }
      ],
      [
        {
          text:
            '(1)お客さまが新たに電気需給契約を希望される場合は、あらかじめ要綱等を承諾のうえ、当社が必要とする事項を明らかにし、所定の様式により申し込みをしていただきます。',
          type: ComponentDescriptionType.Normal
        }
      ],
      [
        {
          text:
            '(2)申し込みは、当社所定の場所で受け付けます。なお、当社が適当と判断した場合は、口頭、電話、インターネット等による申し込みを受け付けることがあります。',
          type: ComponentDescriptionType.Normal
        }
      ],
      [
        {
          text: '(3)電気需給契約の申し込みをされる場合は、お客さまは、あらかじめ、次の事項を承諾するものといたします。',
          type: ComponentDescriptionType.Normal
        }
      ],
      [
        {
          text:
            '　　ア　要綱等によって支払いを要することとなった料金その他の債務について、当社の定める期日を経過してなお支払われない場合等には、お客さまの氏名、住所、支払状況等の情報（お客さまを識別できる情報をいいます）を他の小売電気事業者等へ当社が通知すること。',
          type: ComponentDescriptionType.Normal
        }
      ],
      [
        {
          text:
            '　　イ　東京電力パワーグリッド株式会社（以下「接続供給会社」といいます。）が託送供給等約款及びその他の供給条件等（以下「託送約款等」といいます。）において定める需要家等に関する事項を遵守すること。',
          type: ComponentDescriptionType.Normal
        }
      ],
      [
        {
          text:
            '　　ウ　電気需給契約に基づきお客さまから申し出ていただいた事項のうち、託送約款等に基づく接続供給のために接続供給会社が必要とする事項について、接続供給会社に当社が情報を提供すること。',
          type: ComponentDescriptionType.Normal
        }
      ],
      [
        {
          text: '(4)電気需給契約は、お客さまからの申し込みに対して、当社が承諾したときに成立いたします。',
          type: ComponentDescriptionType.Normal
        }
      ],
      [
        {
          text:
            '(5)契約期間は、電気需給契約が成立した日から、料金適用開始の日が属する年度（4月1日から翌年3月31日までの期間をいいます）の末日までといたします。',
          type: ComponentDescriptionType.Normal
        }
      ],
      [
        {
          text:
            '(6)契約期間満了に先立って、原則として、契約期間満了日の３か月前までにお客さまと当社の双方が、電気需給契約の廃止又は変更について書面等による申し入れを行わない場合は、電気需給契約は、契約期間満了後も1年ごとに同一条件で継続されるものといたします。',
          type: ComponentDescriptionType.Normal
        }
      ],
      [
        {
          text:
            '(7)当社は、法令、電気の需給状況、供給設備の状況、料金の支払状況（既に消滅しているものを含む他の契約の料金支払状況を含みます。）その他によって、申し込みの全部または一部をお断りすることがあります。',
          type: ComponentDescriptionType.Normal
        }
      ],
      [
        {
          text: '',
          type: ComponentDescriptionType.Normal
        }
      ],
      [
        {
          text: '２．使用開始予定日',
          type: ComponentDescriptionType.Normal
        }
      ],
      [
        {
          text:
            '(1)当社へスイッチングされる場合の供給開始予定日は、原則として、従前の小売電気事業者（旧小売電気事業者）との解約や接続供給会社との託送供給契約成立等の手続きが完了した後の、接続供給会社の託送約款等に定める計量日（次回計量日又は次々回計量日）といたします。',
          type: ComponentDescriptionType.Normal
        }
      ],
      [
        {
          text:
            '(2)転宅等で新たに電気の使用を開始される場合の供給開始予定日は、お客さまが希望される日を基準として、協議することといたします。',
          type: ComponentDescriptionType.Normal
        }
      ],
      [
        {
          text: '(3)供給開始後に、ご契約内容をお知らせする書面を送付いたします。',
          type: ComponentDescriptionType.Normal
        }
      ],
      [
        {
          text:
            '(4)旧小売電気事業者への解約連絡は当社がお客さまに代わり行いますので、当社の供給開始とともに旧小売電気事業者との契約は解約されます。',
          type: ComponentDescriptionType.Normal
        }
      ],
      [
        {
          text:
            '(5)万が一、供給開始予定日より前にスイッチングの申し込みをキャンセルされる場合は、供給開始予定日の３営業日前までに当社へその旨をお申し出いただく必要がございます。',
          type: ComponentDescriptionType.Normal
        }
      ],
      [
        {
          text: '',
          type: ComponentDescriptionType.Normal
        }
      ],
      [
        {
          text: '３．料金プラン・割引種別の適用等',
          type: ComponentDescriptionType.Normal
        }
      ],
      [
        {
          text: '(1)料金プラン・割引種別はお客さまからの申し込みに基づき適用いたします。',
          type: ComponentDescriptionType.Normal
        }
      ],
      [
        {
          text:
            '(2)当社とのガス需給契約の解約等で適用条件を満たさなくなった場合は、すみやかにその旨を当社へ連絡いただきます。',
          type: ComponentDescriptionType.Normal
        }
      ],
      [
        {
          text: '',
          type: ComponentDescriptionType.Normal
        }
      ],
      [
        {
          text: '４．電気ご使用量の計量や電気料金の算定方法等',
          type: ComponentDescriptionType.Normal
        }
      ],
      [
        {
          text:
            '(1)接続供給会社が託送約款等に基づき計量した値を用いて、その料金算定期間の使用量を算定いたします。計量器は、託送約款等に基づき接続供給会社が設置いたします。料金の算定期間における使用量は、30分ごとの使用量の合計として算定いたします。',
          type: ComponentDescriptionType.Normal
        }
      ],

      [
        {
          text:
            '(2)計量器の故障や特別の事情等があり、使用量の算定に計量値等を用いることが適当でないときには、託送約款等に定めるところにより、お客さまと当社との協議によって使用量を定めます。',
          type: ComponentDescriptionType.Normal
        }
      ],
      [
        {
          text: '(3)当社は、その使用量をWEB会員サービス「カテエネ」・「ビジエネ」によりお客さまへお知らせいたします。',
          type: ComponentDescriptionType.Normal
        }
      ],
      [
        {
          text:
            '（供給開始後に当社より送付するご契約内容をお知らせする書面内に「カテエネ」・「ビジエネ」の登録方法を記載しています。）',
          type: ComponentDescriptionType.Normal
        }
      ],
      [
        {
          text: '(4)当社は、電気個別要綱の料金表を適用して、その使用量に基づき電気料金を算定いたします。',
          type: ComponentDescriptionType.Normal
        }
      ],
      [
        {
          text:
            '(5)料金算定期間は、計量日（電力量又は最大需要電力等が記録型計量器に記録される日をいいます。）から次の計量日の前日までの期間とします。また、お引っ越し等により、ご使用期間が1か月に満たない場合、要綱等に定める算定式に基づき日割り計算を行います。',
          type: ComponentDescriptionType.Normal
        }
      ],
      [
        {
          text:
            '(6)電気料金は、契約電流、契約容量若しくは契約電力によって決まる「基本料金」と、電気ご使用量に応じて決まる「電力量料金（燃料費調整額を含む）」の合計（割引制度の適用がある場合は、その合計から割引額を差し引いた金額）に、再生可能エネルギー発電促進賦課金を加えたものといたします。ただし、適用する割引種別により割引額には上限があります。',
          type: ComponentDescriptionType.Normal
        }
      ],
      [
        {
          text:
            '　※燃料費調整制度および再生可能エネルギー発電促進賦課金の詳細及び適用単価は、当社ホームページ等をご確認ください。',
          type: ComponentDescriptionType.Normal
        }
      ],
      [
        {
          text: '＜計算方法＞',
          type: ComponentDescriptionType.Normal
        }
      ],
      [
        {
          text:
            '電気料金 = 基本料金（税込）+ 電力量料金単価（税込）×電気ご使用量 ± 燃料費調整単価（税込）×電気ご使用量 - 割引額（税込） + 再生可能エネルギー発電促進賦課金単価（税込）×電気ご使用量',
          type: ComponentDescriptionType.Normal
        }
      ],
      [
        {
          text: '(7)料金プランの料金表及び適用条件については、要綱等をご確認ください。',
          type: ComponentDescriptionType.Normal
        }
      ],
      [
        {
          text: '',
          type: ComponentDescriptionType.Normal
        }
      ],
      [
        {
          text: '５．電気料金等のお支払い',
          type: ComponentDescriptionType.Normal
        }
      ],
      [
        {
          text:
            '(1)電気料金又は延滞利息については毎月、工事費負担金その他についてはそのつど、当社が指定した金融機関等を通じてお支払いいただきます。なお、電気料金又は延滞利息は原則として、口座振替又はクレジットカード払いによりお支払いいただきます。ただし、供給開始後、お支払方法の手続きが完了するまでに電気料金又は延滞利息をお支払いいただく場合等には、振込用紙によりお支払いいただきます。',
          type: ComponentDescriptionType.Normal
        }
      ],
      [
        {
          text:
            '(2)お客さまが、電気需給契約と同一の需要場所において当社とガス需給契約を締結されている場合の電気料金は、原則として、そのガス需給契約におけるガス料金の支払いと同一の方法により、ガス料金とあわせてお支払いいただきます。',
          type: ComponentDescriptionType.Normal
        }
      ],
      [
        {
          text:
            '(3)電気料金の支払義務は、要綱等の定めに基づき、原則として、検針日の属する月の翌月第３営業日に発生し、支払期限日は、支払義務発生日の翌日から起算して30日目といたします。',
          type: ComponentDescriptionType.Normal
        }
      ],
      [
        {
          text:
            '(4)支払期限日を経過してもなお電気料金のお支払いがない場合は、要綱等の定めに基づき延滞利息を申し受けます。',
          type: ComponentDescriptionType.Normal
        }
      ],
      [
        {
          text: '',
          type: ComponentDescriptionType.Normal
        }
      ],
      [
        {
          text: '６．供給電圧及び周波数',
          type: ComponentDescriptionType.Normal
        }
      ],
      [
        {
          text: '　当社が供給する電気の供給電圧及び周波数は次の通りです。',
          type: ComponentDescriptionType.Normal
        }
      ],
      [
        {
          text: '　[供給電圧]標準電圧100ボルト又は200ボルト',
          type: ComponentDescriptionType.Normal
        }
      ],
      [
        {
          text: '　[周波数]標準周波数50ヘルツ（一部地域は60ヘルツ）',
          type: ComponentDescriptionType.Normal
        }
      ],
      [
        {
          text: '',
          type: ComponentDescriptionType.Normal
        }
      ],
      [
        {
          text: '７．お客さまの申し出による電気需給契約の変更又は解約',
          type: ComponentDescriptionType.Normal
        }
      ],
      [
        {
          text:
            '(1)お客さまのお申し出による契約の変更及び転宅等による解約については、当社ホームページ又は当社問い合わせ先へのお電話により、お手続きをしていただきます。転宅等による解約を希望される場合は、解約を希望される日の３営業日前までに当社へお申し出いただく必要があります。',
          type: ComponentDescriptionType.Normal
        }
      ],
      [
        {
          text:
            '(2)お客さまが当社から他の小売電気事業者へスイッチングされる場合の解約については、新たな小売電気事業者に対し契約の申し込みをしていただきます。（当社への解約のお申し出は不要です。）',
          type: ComponentDescriptionType.Normal
        }
      ],
      [
        {
          text:
            '(3)お客さまが契約電流、契約容量若しくは契約電力を新たに設定し、又は増加された日以降1年に満たないで電気の使用を廃止しようとし、又は契約容量若しくは契約電力を減少しようとされる場合は、当社は電気需給契約の消滅又は変更の日に、電気料金及び工事費を精算していただきます。ただし、将来の需要等を考慮して供給設備を常置する場合、又は非常変災等やむを得ない理由による場合を除きます。',
          type: ComponentDescriptionType.Normal
        }
      ],
      [
        {
          text: '',
          type: ComponentDescriptionType.Normal
        }
      ],
      [
        {
          text: '８．当社からの申し出による電気需給契約の変更又は解約',
          type: ComponentDescriptionType.Normal
        }
      ],
      [
        {
          text:
            '(1)当社は、要綱等を変更することがあります。この場合には、原則として、料金にかかわる供給条件は変更の直後の計量日から、その他の供給条件は変更を行った日から、変更後の要綱等によります。その場合には、変更後の要綱等を当社のホームページに掲示する方法又はその他当社が適当と判断した方法により公表いたします。なお、お客さまは、変更を承諾いただけない場合は契約を解約することができます。',
          type: ComponentDescriptionType.Normal
        }
      ],
      [
        {
          text:
            '(2)要綱等又は電気需給契約の内容を変更する場合は、次項に定める場合を除き、電気事業法第2条の13第2項に基づく供給条件の説明及び書面の交付については、書面の交付、インターネット上での開示、又は電子メールを送信する方法その他当社が適当と判断した方法により行い、説明を要する事項のうち当該変更をしようとする事項のみを説明し記載すれば足りるものといたします。また、同法第2条の14第1項に基づく書面の交付については、書面の交付、インターネット上での開示、又は電子メールを送信する方法その他当社が適当と判断した方法により行い、当社の名称及び住所、契約年月日、当該変更をした事項並びに供給地点特定番号のみを記載すれば足りるものといたします。',
          type: ComponentDescriptionType.Normal
        }
      ],
      [
        {
          text:
            '(3)要綱等又は電気需給契約の内容について、法令の制定又は改廃に伴い当然必要とされる形式的な変更その他の電気需給契約の実質的な変更を伴わない変更をしようとする場合、電気事業法第2条の13第2項に基づく供給条件の説明については、説明を要する事項のうち当該変更をしようとする事項の概要のみを書面交付することなく説明すれば足りるものといたします。また同法第2条の14第1項に基づく書面の交付については、これを行わないものといたします。',
          type: ComponentDescriptionType.Normal
        }
      ],
      [
        {
          text:
            '(4)お客さまが支払期限日をさらに20日経過しても電気料金のお支払いがない場合、当社との他の契約（すでに消滅しているものを含みます。）の電気料金についてお支払いがない場合、又は要綱等によって支払いを要することとなった電気料金以外の債務のお支払いがない場合には、当社は電気需給契約を解約することがあります。また、電気を不正に使用した等、当社が要綱等に定める一定の事由に該当するときは、電気の供給を停止又は解約することがあります。',
          type: ComponentDescriptionType.Normal
        }
      ],
      [
        {
          text:
            '(5)お客さまが、当社に電気の使用廃止の通知をすることなく移転され、電気の使用がないことが明らかな場合には、当社及び接続供給会社が需給を終了させるための処置を行なった日に契約を解約いたします。',
          type: ComponentDescriptionType.Normal
        }
      ],
      [
        {
          text: '',
          type: ComponentDescriptionType.Normal
        }
      ],
      [
        {
          text: '９．工事費等の負担',
          type: ComponentDescriptionType.Normal
        }
      ],
      [
        {
          text:
            '　　当社が、接続供給会社からお客さまの需要場所に対応する供給地点に係る工事費等の負担を求められた場合には、当社は、その金額をお客さまから、原則として、当社又は接続供給会社の工事着手前に申し受けます。また、当社は、接続供給会社の設計変更、材料単価の変動その他特別の事情によって工事費等に著しい差異が生じた場合等において、接続供給会社との間で工事完成後に工事費等の精算を行う場合は、お客さまとの間で工事費等を精算するものといたします。',
          type: ComponentDescriptionType.Normal
        }
      ],
      [
        {
          text: '',
          type: ComponentDescriptionType.Normal
        }
      ],
      [
        {
          text: '１０．違約金及び設備賠償金',
          type: ComponentDescriptionType.Normal
        }
      ],
      [
        {
          text:
            '(1)お客さまが、電気工作物の改変等によって不正に電気を使用された場合等で、そのために電気料金の全部又は一部の支払いを免れた場合には、当社は、その免れた金額の3倍に相当する金額を、違約金として申し受けます。免れた金額は、適正な供給条件に基づいて算定された金額と、不正な使用方法に基づいて算定された金額との差額といたします。',
          type: ComponentDescriptionType.Normal
        }
      ],
      [
        {
          text: '(2)不正に使用した期間が確認できないときは、6か月以内で当社が決定した期間といたします。',
          type: ComponentDescriptionType.Normal
        }
      ],
      [
        {
          text:
            '(3)お客さまが故意又は過失によって、その需要場所内の当社又は接続供給会社の電気工作物、電気機器その他の設備を損傷し、又は亡失した場合は、その設備について、当社設備の場合かつ修理可能であるときは修理費、当社設備の場合かつ亡失又は修理不可能であるときは、帳簿価額と取替工費との合計額を賠償していただきます。また、接続供給会社の設備の場合は、接続供給会社に生じた損害の賠償に要する金額を賠償していただきます。',
          type: ComponentDescriptionType.Normal
        }
      ],
      [
        {
          text: '',
          type: ComponentDescriptionType.Normal
        }
      ],
      [
        {
          text: '１１．需要場所への立ち入りによる業務の実施',
          type: ComponentDescriptionType.Normal
        }
      ],
      [
        {
          text:
            '　　当社又は接続供給会社は、供給設備又は計量器等の設計、施工、改修又は検査や、計量器の検針又は計量値の確認等を実施するため、お客さまの承諾を得てお客さまの土地若しくは建物に立ち入らせていただくことがあります。この場合には、正当な理由がない限り、立ち入ること及び業務を実施することを承諾していただきます。',
          type: ComponentDescriptionType.Normal
        }
      ],
      [
        {
          text: '',
          type: ComponentDescriptionType.Normal
        }
      ],
      [
        {
          text: '１２．保安に対するお客さまの協力',
          type: ComponentDescriptionType.Normal
        }
      ],
      [
        {
          text:
            '(1)お客さまが、次のいずれかについてお気づきの場合には、すみやかに当社及び接続供給会社にご連絡いただくようご協力ください。この場合には、接続供給会社は、ただちに適当な処置をいたします。',
          type: ComponentDescriptionType.Normal
        }
      ],
      [
        {
          text:
            '　　ア　電気の供給に必要な電気工作物に異状、若しくは故障があり、又は異状若しくは故障が生ずるおそれがあると認めた場合',
          type: ComponentDescriptionType.Normal
        }
      ],
      [
        {
          text:
            '　　イ　お客さまの電気工作物に異状若しくは故障があり、又は異状若しくは故障が生ずるおそれがあり、それが接続供給会社の供給設備に影響を及ぼすおそれがあると認めた場合',
          type: ComponentDescriptionType.Normal
        }
      ],
      [
        {
          text:
            '(2)お客さまが、接続供給会社の供給設備を使用しないことが明らかな場合で、接続供給会社が保安上必要と認めるときは、その期間について、接続供給会社は(1)に準じて、適当な処置をいたします。',
          type: ComponentDescriptionType.Normal
        }
      ],
      [
        {
          text: '(3)その他、接続供給会社の託送約款等に定める事項を遵守していただきます。',
          type: ComponentDescriptionType.Normal
        }
      ],
      [
        {
          text: '',
          type: ComponentDescriptionType.Normal
        }
      ],
      [
        {
          text: '１３．その他',
          type: ComponentDescriptionType.Normal
        }
      ],
      [
        {
          text:
            '(1)お客さまが当社にスイッチングされると、旧小売電気事業者との契約は解約となりますので、その契約内容によっては旧小売電気事業者に対する解約金が発生する場合があります。また、旧小売電気事業者で利用されているポイント等のサービスが失効・停止する場合等、お客さまの不利益になる事項が発生する場合があります。',
          type: ComponentDescriptionType.Normal
        }
      ],
      [
        {
          text:
            '(2)クーリング・オフにより契約を解除された場合や当社から契約を解約した場合等で、お客さまが無契約状態となったときには、電気の供給が停止いたしますので、契約の締結を希望される小売電気事業者へ申し込みいただく必要があります。',
          type: ComponentDescriptionType.Normal
        }
      ],
      [
        {
          text:
            '(3)当社又は接続供給会社が解約をし、又は供給若しくは使用の制限、中止若しくは停止をしたために、お客さま又は第三者が損害を受けられても、当社の責めに帰すべき事由がないときは、当社は賠償の責任を負いません。',
          type: ComponentDescriptionType.Normal
        }
      ],
      [
        {
          text:
            '(4)当社は、個人情報の一部を共同利用することがあります。共同利用における利用項目、利用者の範囲、利用目的等の詳細は当社のプライバシーポリシーをご確認ください。',
          type: ComponentDescriptionType.Normal
        }
      ],
      [
        {
          text: '',
          type: ComponentDescriptionType.Normal
        }
      ],
      [
        {
          text: '１４．「KODOMO新聞でんき」限定',
          type: ComponentDescriptionType.Normal
        }
      ],
      [
        {
          text:
            '　　料金プランが「KODOMO新聞でんき」のお客さまは、上記事項に加えて、以下の事項についてご確認ください。',
          type: ComponentDescriptionType.Normal
        }
      ],
      [
        {
          text: '(1)当社は、要綱等に基づき、お客さまに読売KODOMO新聞をお届けします。',
          type: ComponentDescriptionType.Normal
        }
      ],
      [
        {
          text: '(2)配達先は、電気需給契約の需要場所と同一の場所といたします。',
          type: ComponentDescriptionType.Normal
        }
      ],
      [
        {
          text: '(3)配達期間は、電気需給契約が成立した日の属する月の翌月から電気需給契約の廃止までといたします。',
          type: ComponentDescriptionType.Normal
        }
      ],
      [
        {
          text: '(4)配達日は、前項の配達期間内において読売KODOMO新聞が発行された日といたします。',
          type: ComponentDescriptionType.Normal
        }
      ],
      [
        {
          text:
            '(5)お客さまが電気需給契約の申し込み時に、既に同一配達先に向けた読売KODOMO新聞の新聞購読契約（以下「既契約」といいます。）を締結している場合、電気需給契約が成立した日の属する月の末日をもって終了するよう当社はお客さまの配達先を担当する読売新聞販売店（以下「YC」といいます。）へ連絡いたします。',
          type: ComponentDescriptionType.Normal
        }
      ],
      [
        {
          text: '(6) 当社は、配達に必要となるお客さまの個人情報を、株式会社読売ハートサービス及びYCに提供いたします。',
          type: ComponentDescriptionType.Normal
        }
      ]
    ],
    gas: [
      [
        {
          text: 'ガス需給契約に関する重要事項説明',
          type: ComponentDescriptionType.Title
        }
      ],

      [
        {
          text:
            'お客さまが、西部ガス株式会社（以下「当社」といいます。）にガス使用の申し込みをしていただくにあたり、当社がガス事業法に基づき説明し、お客さまにご確認いただきたい主要な供給条件は以下のとおりです。なお、ガスの供給及び使用に関する契約（以下「ガス需給契約」といいます。）の詳細は、ガス基本契約要綱及びガス個別要綱（以下「要綱等」といいます。）に定めています。当社は、ガス事業法第14条に基づく書面の交付（契約締結前書面）及び同法第15条に基づく書面の交付（契約締結後書面）について、書面でお知らせする事項を除いては、書面交付に代えて、要綱等を当社のホームページに掲載する方法によりこれを提供いたします。',
          type: ComponentDescriptionType.Normal
        }
      ],
      [
        {
          text: '',
          type: ComponentDescriptionType.Normal
        }
      ],
      [
        {
          text: '１．ガス使用の申し込み及びガス需給契約の成立',
          type: ComponentDescriptionType.Normal
        }
      ],
      [
        {
          text:
            '(1)お客さまが新たにガス需給契約を希望される場合は、あらかじめ要綱等を承諾のうえ、当社が必要とする事項を明らかにし、所定の様式により申し込みをしていただきます。',
          type: ComponentDescriptionType.Normal
        }
      ],
      [
        {
          text:
            '(2)申し込みは、当社所定の場所で受け付けます。なお、当社が適当と判断した場合は、口頭、電話、インターネット等による申込みを受け付けることがあります。',
          type: ComponentDescriptionType.Normal
        }
      ],
      [
        {
          text:
            '(3)ガス需給契約のお申し込みをされる場合は、お客さまは、あらかじめ、次の事項を承諾するものといたします。',
          type: ComponentDescriptionType.Normal
        }
      ],
      [
        {
          text: 'ア　一般ガス導管事業者が託送供給約款において定める需要家等に関する事項を遵守すること。',
          type: ComponentDescriptionType.Normal
        }
      ],
      [
        {
          text:
            'イ　当社が法令に基づき実施した消費機器調査の結果等について、一般ガス導管事業者へ調査後遅滞なく提供すること。',
          type: ComponentDescriptionType.Normal
        }
      ],
      [
        {
          text:
            'ウ　法令に定める直近のガス機器調査の結果等、需給契約の締結に必要な事項について、一般ガス導管事業者からガス小売事業者へ提供すること。',
          type: ComponentDescriptionType.Normal
        }
      ],
      [
        {
          text:
            'エ　消費段階における事故が発生した場合には、当社は、一般ガス導管事業者から、一般ガス導管事業者が事故現場で把握した情報の提供を受けること。',
          type: ComponentDescriptionType.Normal
        }
      ],
      [
        {
          text: '(4)ガス需給契約は、お客さまからの申し込みを受け、当社が承諾したときに成立します。',
          type: ComponentDescriptionType.Normal
        }
      ],
      [
        {
          text:
            '(5)当社は、法令、ガスの製造供給能力、ガス工作物の状況、料金の支払状況（既に消滅しているものを含む他の契約の料金支払状況を含みます。）その他の状況に鑑み、適当でないと判断した場合には、申し込みを承諾しないことがあります。',
          type: ComponentDescriptionType.Normal
        }
      ],
      [
        {
          text: '',
          type: ComponentDescriptionType.Normal
        }
      ],
      [
        {
          text: '２．使用開始予定日',
          type: ComponentDescriptionType.Normal
        }
      ],
      [
        {
          text:
            '(1)当社へスイッチングされる場合の供給開始予定日は、原則として、従前のガス小売事業者（旧ガス小売事業者）との解約や一般ガス導管事業者との託送供給契約成立等の手続きが完了した後の定例検針日（次回検針日または次々回検針日）の翌日といたします。',
          type: ComponentDescriptionType.Normal
        }
      ],
      [
        {
          text:
            '(2)転宅等で新たにガスの使用を開始される場合の供給開始予定日は、お客さまが希望される日を基準として、協議することといたします。',
          type: ComponentDescriptionType.Normal
        }
      ],
      [
        {
          text: '(3)供給開始後に、ご契約内容をお知らせする書面を送付いたします。',
          type: ComponentDescriptionType.Normal
        }
      ],
      [
        {
          text:
            '(4)旧ガス小売事業者への解約連絡は、原則として、当社がお客さまに代わり行いますので、当社の供給開始とともに旧ガス小売事業者との契約は解約されます。',
          type: ComponentDescriptionType.Normal
        }
      ],
      [
        {
          text:
            '(5)万が一、供給開始予定日より前にスイッチングの申し込みをキャンセルされる場合は、供給開始予定日の３営業日前までに当社へその旨をお申し出いただく必要がございます。',
          type: ComponentDescriptionType.Normal
        }
      ],
      [
        {
          text: '',
          type: ComponentDescriptionType.Normal
        }
      ],
      [
        {
          text: '３．料金プラン・割引種別の適用等',
          type: ComponentDescriptionType.Normal
        }
      ],
      [
        {
          text:
            '(1)料金プラン・割引種別はお客さまからの申し込みにもとづき適用いたします。その適用条件の対象となる機器の所有状況について、お客さま宅へのご訪問等の機会を通じて確認させていただく場合があります。適用条件を満たさないでガスをご使用の場合、要綱等に定める方法により精算させていただきます。',
          type: ComponentDescriptionType.Normal
        }
      ],
      [
        {
          text:
            '(2)対象機器の撤去や当社との電気需給契約の解約等で適用条件を満たさなくなった場合は、すみやかにその旨を当社へ連絡いただきます。',
          type: ComponentDescriptionType.Normal
        }
      ],
      [
        {
          text: '',
          type: ComponentDescriptionType.Normal
        }
      ],
      [
        {
          text: '４．ガスご使用量の計量やガス料金の算定方法等',
          type: ComponentDescriptionType.Normal
        }
      ],
      [
        {
          text:
            '(1)一般ガス導管事業者が託送供給約款に基づき検針を行い、前回の検針日及び今回の検針日におけるガスメーターの読みによりその料金算定期間の使用量を算定いたします。',
          type: ComponentDescriptionType.Normal
        }
      ],
      [
        {
          text:
            '(2)ガスメーターの故障その他の事由により使用量が不明の場合には、託送供給約款に定めるところによりお客さまとの協議により使用量を算定いたします。',
          type: ComponentDescriptionType.Normal
        }
      ],
      [
        {
          text: '(3)当社は、その使用量をWEB会員サービス「カテエネ」・「ビジエネ」によりお客さまへお知らせいたします。',
          type: ComponentDescriptionType.Normal
        }
      ],
      [
        {
          text:
            '（供給開始後に当社より送付するご契約内容をお知らせする書面内に「カテエネ」・「ビジエネ」の登録方法を記載しています。）',
          type: ComponentDescriptionType.Normal
        }
      ],
      [
        {
          text:
            '(4)当社は、ガス個別要綱の料金表を適用して、その使用量に基づきガス料金を算定いたします。料金算定期間は、次の期間をいいます。',
          type: ComponentDescriptionType.Normal
        }
      ],
      [
        {
          text: 'ア　検針日の翌日から次の検針日までの期間（イ及びウの場合を除きます）',
          type: ComponentDescriptionType.Normal
        }
      ],
      [
        {
          text:
            'イ　新たにガスの使用を開始した場合又はガスの供給を再開した場合、その開始又は再開の日から次の検針日までの期間',
          type: ComponentDescriptionType.Normal
        }
      ],
      [
        {
          text: 'ウ　ガスの供給を停止した日にガスの供給を再開した場合、供給再開日の翌日から次の検針日までの期間',
          type: ComponentDescriptionType.Normal
        }
      ],
      [
        {
          text:
            '(5)新たにガスの使用を開始し、又は解約を行った場合（スイッチングによる解約の場合を除きます）等で料金算定期間が29日以下又は36日以上となったときや、定例検針日の翌日から次の定例検針日までの期間が24日以下又は36日以上となったとき等に、要綱等に定める算定式に基づき、当該料金算定期間の料金を日割計算により算定いたします。ただし、当社の都合で料金算定期間の日数が36日以上となった場合を除きます。',
          type: ComponentDescriptionType.Normal
        }
      ],
      [
        {
          text:
            '(6)ガス料金は、1ヶ月あたりの基本料金と、1㎥あたりの単位料金にガスご使用量を乗じた従量料金を合計して算定します。割引制度の適用がある場合は、その合計から割引額を差し引いたものを料金といたします。ただし、適用する割引種別により割引額には上限があります。',
          type: ComponentDescriptionType.Normal
        }
      ],
      [
        {
          text: '(7)単位料金は、ガスの原料価格の変動に応じて毎月調整します。',
          type: ComponentDescriptionType.Normal
        }
      ],
      [
        {
          text: '＜計算方法＞',
          type: ComponentDescriptionType.Normal
        }
      ],
      [
        {
          text:
            'ガス料金 = 基本料金(税込) + 従量料金(単位料金（税込）※1 × ガス使用量) + 割引額（税込）((基本料金 + 従量料金) × 割引率)',
          type: ComponentDescriptionType.Normal
        }
      ],
      [
        {
          text: '※1　原料価格の変動に応じて、毎月調整いたします。',
          type: ComponentDescriptionType.Normal
        }
      ],
      [
        {
          text: '※2　割引制度の適用がある場合、割引額を差し引きます。',
          type: ComponentDescriptionType.Normal
        }
      ],
      [
        {
          text: '(8)ガス料金プランの料金表及び適用条件については、ガス個別要綱等をご確認ください。',
          type: ComponentDescriptionType.Normal
        }
      ],
      [
        {
          text: '',
          type: ComponentDescriptionType.Normal
        }
      ],
      [
        {
          text: '５．ガス料金のお支払い',
          type: ComponentDescriptionType.Normal
        }
      ],
      [
        {
          text:
            '(1)ガス料金又は延滞利息は、原則として、口座振替又はクレジットカード払いにより、毎月お支払いいただきます。ただし、供給開始後、お支払方法の手続きが完了するまでにガス料金又は延滞利息をお支払いいただく場合等には、払込みの方法によりお支払いいただきます。',
          type: ComponentDescriptionType.Normal
        }
      ],
      [
        {
          text:
            '(2)お客さまが、当社と電気需給契約を締結されている場合のガス料金は、原則として、その電気需給契約における電気料金の支払いと同一の方法により、電気料金とあわせてお支払いいただきます。',
          type: ComponentDescriptionType.Normal
        }
      ],
      [
        {
          text:
            '(3)ガス料金の支払義務は、要綱等の定めに基づき、原則として、検針日の属する月の翌月第３営業日に発生し、支払期限日は、支払義務発生日の翌日から起算して30日目といたします。',
          type: ComponentDescriptionType.Normal
        }
      ],
      [
        {
          text: '(4)支払期限日を経過してもなお料金のお支払いがない場合は、要綱等の定めに基づき延滞利息を申し受けます。',
          type: ComponentDescriptionType.Normal
        }
      ],
      [
        {
          text: '',
          type: ComponentDescriptionType.Normal
        }
      ],
      [
        {
          text: '６．供給するガスの熱量、圧力及びガスグループ',
          type: ComponentDescriptionType.Normal
        }
      ],
      [
        {
          text: '当社が供給するガスの熱量、圧力及びガスグループは次の通りです。',
          type: ComponentDescriptionType.Normal
        }
      ],
      [
        {
          text: '　[熱量]標準熱量45メガジュール、最低熱量44メガジュール',
          type: ComponentDescriptionType.Normal
        }
      ],
      [
        {
          text: '　[圧力]最高圧力2.5キロパスカル、最低圧力1.0キロパスカル',
          type: ComponentDescriptionType.Normal
        }
      ],
      [
        {
          text: '　[ガスグループ]13A',
          type: ComponentDescriptionType.Normal
        }
      ],
      [
        {
          text:
            'ただし、上記の最高圧力を超えるガスの使用のお申し込みがある場合には、お客さま及び一般ガス導管事業者と協議の上、圧力を定めて供給することがあります。',
          type: ComponentDescriptionType.Normal
        }
      ],
      [
        {
          text: '',
          type: ComponentDescriptionType.Normal
        }
      ],
      [
        {
          text: '７．お客さまの申し出によるガス需給契約の変更又は解約',
          type: ComponentDescriptionType.Normal
        }
      ],
      [
        {
          text:
            '(1)お客さまのお申し出による契約の変更及び転宅等による解約については、記載のお問い合わせ先までご連絡ください。契約を変更された場合の料金適用開始日は契約変更後の定例検針日の翌日といたします。また、転宅等による解約を希望される場合は、解約を希望される日の３営業日前までに当社へお申し出いただく必要があります。',
          type: ComponentDescriptionType.Normal
        }
      ],
      [
        {
          text:
            '(2)お客さまが当社から他のガス小売事業者へスイッチングされる場合の解約については、原則として、新たなガス小売事業者に対し契約の申し込みをしていただきます。（当社への解約のお申し出は不要です。）',
          type: ComponentDescriptionType.Normal
        }
      ],
      [
        {
          text: '',
          type: ComponentDescriptionType.Normal
        }
      ],
      [
        {
          text: '８．当社からの申し出によるガス需給契約の変更又は解約',
          type: ComponentDescriptionType.Normal
        }
      ],
      [
        {
          text:
            '(1)当社は、要綱等を変更することがあります。この場合には、原則として、料金にかかわる供給条件は変更の直後の検針日の翌日から、その他の供給条件は変更を行った日から、変更後の要綱等によります。この場合、変更後の要綱等を当社のホームページに掲示する方法又はその他当社が適当と判断した方法により公表いたします。なお、お客さまは、変更を承諾いただけない場合は契約を解約することができます。',
          type: ComponentDescriptionType.Normal
        }
      ],
      [
        {
          text:
            '(2)要綱等又はガス需給契約の内容を変更する場合は、次項に定める場合を除き、ガス事業法第14条に基づく供給条件の説明及び書面の交付については、書面の交付、インターネット上での開示、又は電子メールを送信する方法その他当社が適当と判断した方法により行い、説明を要する事項のうち当該変更をしようとする事項のみを説明し記載すれば足りるものといたします。また、同法第15条に基づく書面の交付については、書面の交付、インターネット上での開示、又は電子メールを送信する方法その他当社が適当と判断した方法により行い、当社の名称及び住所、契約年月日、当該変更をした事項並びに供給地点特定番号のみを記載すれば足りるものといたします。',
          type: ComponentDescriptionType.Normal
        }
      ],
      [
        {
          text:
            '(3)要綱等又はガス需給契約の内容について、法令の制定又は改廃に伴い当然必要とされる形式的な変更その他のガス需給契約の実質的な変更を伴わない変更をしようとする場合、ガス事業法第14条に基づく供給条件の説明については、説明を要する事項のうち当該変更をしようとする事項の概要のみを書面交付することなく説明すれば足りるものといたします。また同法第15条に基づく書面の交付については、これを行わないものといたします。',
          type: ComponentDescriptionType.Normal
        }
      ],
      [
        {
          text:
            '(4)お客さまが支払期限日を経過しても料金、延滞利息その他要綱等に基づく債務のお支払いがない場合、当社との他の契約（すでに消滅しているものを含みます。）の料金又は延滞利息についてお支払いがない場合には、当社はガス需給契約を解約することがあります。また、ガスを不正に使用した等、当社が要綱等に定める一定の事由に該当するときは、ガスの供給を停止又は解約することがあります。',
          type: ComponentDescriptionType.Normal
        }
      ],
      [
        {
          text:
            '(5)お客さまが当社にガスの使用廃止の通知をしない場合であっても、すでに転居されている等明らかにガスの使用を廃止したと認められるときは、当社はガスの供給を終了させるための措置を行うことがあります。その場合、この措置をとった日に解約があったものといたします。',
          type: ComponentDescriptionType.Normal
        }
      ],
      [
        {
          text: '',
          type: ComponentDescriptionType.Normal
        }
      ],
      [
        {
          text: '９．導管、ガスメーターその他の設備に関する費用負担',
          type: ComponentDescriptionType.Normal
        }
      ],
      [
        {
          text:
            '(1)ガス工事をお申し込みされる場合は、一般ガス導管事業者が定めるガス工事約款に基づき、一般ガス導管事業者に申し込みをしていただきます。',
          type: ComponentDescriptionType.Normal
        }
      ],
      [
        {
          text:
            '(2)内管、ガス栓、お客さまのために設置されるガス遮断装置、昇圧供給装置及び整圧器はお客さまの所有とし、お客さまの費用負担で設置していただきます。',
          type: ComponentDescriptionType.Normal
        }
      ],
      [
        {
          text:
            '(3)ガスメーターは一般ガス導管事業者が所有するものを設置し、これに要する設置工事費はお客さまにご負担いただきます。',
          type: ComponentDescriptionType.Normal
        }
      ],
      [
        {
          text:
            '(4)供給管は一般ガス導管事業者が所有し、これに要する工事費は一般ガス導管事業者が負担いたします。ただし、お客さまの依頼により供給管の位置変え等を行う場合は、これに要する工事費はお客さまにご負担いただきます。',
          type: ComponentDescriptionType.Normal
        }
      ],
      [
        {
          text:
            '(5)本支管及び整圧器（お客さまのために設置される整圧器は除きます）は、一般ガス導管事業者の所有とし、一般ガス導管事業者のガス工事約款に定める差額が生じた場合は、その差額に消費税等相当額を加えたものを工事負担金としてお客さまにご負担いただきます。',
          type: ComponentDescriptionType.Normal
        }
      ],
      [
        {
          text:
            '(6)その他設備に関するお客さまの費用負担については、一般ガス導管事業者のガス工事約款の定めに従うものといたします。',
          type: ComponentDescriptionType.Normal
        }
      ],
      [
        {
          text: '',
          type: ComponentDescriptionType.Normal
        }
      ],
      [
        {
          text: '１０．導管、器具、機械その他の設備に関する保安上の責任',
          type: ComponentDescriptionType.Normal
        }
      ],
      [
        {
          text:
            '(1)内管及びガス栓等、一般ガス導管事業者のガス工事約款の規定によりお客さまの資産となる供給施設については、お客さまの責任において管理していただきます。また、一般ガス導管事業者は、ガス事業法令の定めるところにより、お客さまの資産となる供給施設について検査及び緊急時の応急の措置等の保安責任を負います。',
          type: ComponentDescriptionType.Normal
        }
      ],
      [
        {
          text:
            '(2)当社又は一般ガス導管事業者は、お客さまに対し、ガスの使用に伴う危険の発生を防止するため、ガス事業法令の定めるところにより、報道機関、印刷物等を通じて必要な事項をお知らせいたします。',
          type: ComponentDescriptionType.Normal
        }
      ],
      [
        {
          text:
            '(3)当社は、ガス事業法令の定めるところにより、屋内に設置された不完全燃焼防止装置の付いていないふろがま、湯沸し器等のガス機器について、お客さまの承諾を得て、ガス事業法令で定める技術上の基準に適合しているかどうかを調査します。',
          type: ComponentDescriptionType.Normal
        }
      ],
      [
        {
          text:
            '(4)お客さまは、ガス漏れを感知したときは、直ちにメーターガス栓及びその他のガス栓を閉止して、一般ガス導管事業者に通知していただきます。',
          type: ComponentDescriptionType.Normal
        }
      ],
      [
        {
          text:
            '(5)お客さまは、当社及び一般ガス導管事業者がガスの使用に関してお知らせした事項等を遵守して、ガスを適正かつ安全に使用していただきます。',
          type: ComponentDescriptionType.Normal
        }
      ],
      [
        {
          text:
            '(6)その他保安について、要綱等の「保安に対するお客さまの協力」、「お客さまの責任」に定められた事項を遵守していただきます。',
          type: ComponentDescriptionType.Normal
        }
      ],
      [
        {
          text: '',
          type: ComponentDescriptionType.Normal
        }
      ],
      [
        {
          text: '１１．託送供給約款に定められたお客さまの責任に関する事項',
          type: ComponentDescriptionType.Normal
        }
      ],
      [
        {
          text: '(1)ガスの使用にあたり、託送供給約款に定められる以下の事項について承諾いただきます。',
          type: ComponentDescriptionType.Normal
        }
      ],
      [
        {
          text: 'ア　必要な業務のために、お客さまの供給施設又は消費機器の設置の場所へ立ち入ること。',
          type: ComponentDescriptionType.Normal
        }
      ],
      [
        {
          text: 'イ　ガスの供給及び保安上の必要がある場合に、お客さまのガスの使用を中止又は制限すること。',
          type: ComponentDescriptionType.Normal
        }
      ],
      [
        {
          text: 'ウ　ガス需給契約が解約された後も、ガスメーター等の供給施設を引き続き置かせていただくこと。',
          type: ComponentDescriptionType.Normal
        }
      ],
      [
        {
          text:
            '(2)ガス供給に伴い必要なお客さまの協力、保安等や調査に対するお客さまの協力等、託送供給約款に定められるお客さまの協力に関する事項について承諾いただきます。',
          type: ComponentDescriptionType.Normal
        }
      ],
      [
        {
          text: '',
          type: ComponentDescriptionType.Normal
        }
      ],
      [
        {
          text: '１２．その他',
          type: ComponentDescriptionType.Normal
        }
      ],
      [
        {
          text:
            '(1)お客さまが当社にスイッチングされると、旧ガス小売事業者との契約は解約となりますので、その契約内容によっては旧ガス小売事業者に対する解約金が発生する場合があります。また、旧ガス小売事業者で利用されているポイント等のサービスが失効・停止する場合等、お客さまの不利益になる事項が発生する場合があります。',
          type: ComponentDescriptionType.Normal
        }
      ],
      [
        {
          text:
            '(2)クーリング・オフにより契約を解除された場合や当社から契約を解約した場合等で、お客さまが無契約状態となったときには、ガスの供給が停止いたしますので、契約の締結を希望されるガス小売事業者へお申し込みいただく必要があります。',
          type: ComponentDescriptionType.Normal
        }
      ],
      [
        {
          text:
            '(3)当社又は一般ガス導管事業者が解約をし、又は供給若しくは使用の制限、中止若しくは停止をしたために、お客さま又は第三者が損害を受けられても、当社の責めに帰すべき事由がないときは、当社は賠償の責任を負いません。',
          type: ComponentDescriptionType.Normal
        }
      ],
      [
        {
          text:
            '(4)お客さまが境界線内の一般ガス導管事業者のガス工作物を故意又は重過失により損傷し又は失わせて、当社又は一般ガス導管事業者に重大な損害を与えた場合、ガスを不正に使用した場合等、当社又は一般ガス導管事業者が損害を受けたときは、その損害を賠償していただきます。',
          type: ComponentDescriptionType.Normal
        }
      ],
      [
        {
          text:
            '(5)当社は、個人情報の一部を共同利用することがあります。共同利用における利用項目、利用者の範囲、利用目的等の詳細は当社のプライバシーポリシーをご確認ください。',
          type: ComponentDescriptionType.Normal
        }
      ]
    ],
    service: [
      [
        {
          text: 'スマモル賃貸プランに関する重要事項説明',
          type: ComponentDescriptionType.Title
        }
      ],
      [
        {
          text: '',
          type: ComponentDescriptionType.Normal
        }
      ],
      [
        {
          text: '１．料金の特徴',
          type: ComponentDescriptionType.Normal
        }
      ],
      [
        {
          text:
            '●スマモル賃貸プランは、「スマートロック」「駆けつけサービス」「優待・割引サービス」が付帯したプランです。各種サービスの利用料金は、お支払いいただく電気料金に含まれます。',
          type: ComponentDescriptionType.Normal
        }
      ],
      [
        {
          text: '',
          type: ComponentDescriptionType.Normal
        }
      ],
      [
        {
          text: '',
          type: ComponentDescriptionType.Normal
        }
      ],
      [
        {
          text: '２．スマモル賃貸プランのお申込みについて',
          type: ComponentDescriptionType.Normal
        }
      ],
      [
        {
          text:
            '●スマモル賃貸プランのお申込みをご希望の場合、物件管理等を行う事業者（以下、「管理会社等」といいます。）より、お客さまからお知らせいただいたメールアドレス宛または携帯電話番号宛に、当社のお申込みサイトのURLを送付いたします。',
          type: ComponentDescriptionType.Normal
        }
      ],
      [
        {
          text: '●お客さまは、URLの案内に従い、お申込みに必要な情報を入力いただきます。',
          type: ComponentDescriptionType.Normal
        }
      ],
      [
        {
          text:
            '●お申込みいただいた内容は、スマモル賃貸プランの申込みに利用するほか、スマモル賃貸サービスのご利用に必要な範囲で、管理会社等に連携いたします。',
          type: ComponentDescriptionType.Normal
        }
      ],
      [
        {
          text:
            '●お申込みサイトには、お客さまからあらかじめ管理会社等にお伝えいただいた入居情報が表示されます。表示内容が異なる場合は、お申込み内容の入力前に、必ず管理会社等にお問い合わせください。',
          type: ComponentDescriptionType.Normal
        }
      ],
      [
        {
          text: '',
          type: ComponentDescriptionType.Normal
        }
      ],
      [
        {
          text: '３．付帯サービスについて',
          type: ComponentDescriptionType.Normal
        }
      ],
      [
        {
          text: '●スマモル賃貸プランには、以下のサービスが付帯します。',
          type: ComponentDescriptionType.Normal
        }
      ],
      [
        {
          text: '　①スマートロック',
          type: ComponentDescriptionType.Normal
        }
      ],
      [
        {
          text:
            '　　株式会社ビットキーが提供する、お客さまの需要場所の入口等の鍵に取り付けることで、スマートフォンや専用のリモコンキーから扉の鍵を開閉できる商品「bitlockLITE」をご利用いただけます。',
          type: ComponentDescriptionType.Normal
        }
      ],
      [
        {
          text: '　②駆けつけサービス',
          type: ComponentDescriptionType.Normal
        }
      ],
      [
        {
          text:
            '　　大阪ガスセキュリティサービス株式会社が提供する、暮らしの中のトラブルに対し、警備員による出張対応等を行うサービスをご利用いただけます。',
          type: ComponentDescriptionType.Normal
        }
      ],
      [
        {
          text: '　③優待・割引サービス',
          type: ComponentDescriptionType.Normal
        }
      ],
      [
        {
          text:
            '　　トラブル、グルメ、レジャー、エンターテインメントなどの様々なサービスが市価または定価に比べ割安に利用できるサービスをご利用いただけます。',
          type: ComponentDescriptionType.Normal
        }
      ],
      [
        {
          text: '●付帯サービスは、原則として料金適用開始の日からご利用いただけます。',
          type: ComponentDescriptionType.Normal
        }
      ],
      [
        {
          text:
            '●スマモル賃貸サービスの内容は、それぞれのサービスの要綱または利用規約等にて定めるものといたします。当社以外の事業者がサービス内容または利用規約等を変更したことにより、お客さまに不利益が生じても、当社はその責任を負いません。',
          type: ComponentDescriptionType.Normal
        }
      ],
      [
        {
          text:
            '●お客さまの需要場所等により、一部のサービスを提供できない場合があります。お申込みの前に管理会社等にお問い合わせください。',
          type: ComponentDescriptionType.Normal
        }
      ],
      [
        {
          text: '',
          type: ComponentDescriptionType.Normal
        }
      ],
      [
        {
          text: '４．契約期間について',
          type: ComponentDescriptionType.Normal
        }
      ],
      [
        {
          text:
            '●ご契約期間は、需給契約が成立した日から、料金適用開始の日が属する月をひと月目として24か月目の月の末日といたします。',
          type: ComponentDescriptionType.Normal
        }
      ],
      [
        {
          text: '',
          type: ComponentDescriptionType.Normal
        }
      ],
      [
        {
          text: '５．契約の更新について',
          type: ComponentDescriptionType.Normal
        }
      ],
      [
        {
          text:
            '●ご契約期間満了１か月前を目途に電磁的方法等によりお知らせいたします。同内容で契約を継続される場合、特段のお手続きは不要です。この場合、１年ごとに同条件で契約が継続されます。また、継続後の期間については、６．で定める解約金は適用されません。契約種別の変更や契約期間満了による終了をご希望の場合は、お送りするお知らせに従って、お手続きください。',
          type: ComponentDescriptionType.Normal
        }
      ],
      [
        {
          text: '',
          type: ComponentDescriptionType.Normal
        }
      ],
      [
        {
          text: '６．契約種別の変更・解約について',
          type: ComponentDescriptionType.Normal
        }
      ],
      [
        {
          text:
            '●契約期間途中に、当社との需給契約を廃止し、他の小売電気事業者との需給契約等にもとづき当該需要場所で引き続き電気を使用される場合、当社による解約または当社の他の契約プランへの変更により、お客さまにスマモル賃貸プランの適用がなくなった場合は、原則として、適用がなくなった日から当該契約が満了する予定であった日までの残存期間（１カ月未満の端数は切り捨てます。）に応じて、１か月あたり1,200円を解約金として最終の電気料金とあわせて当社にお支払いいただきます。ただし、契約期間満了日が属する月の前月１日から契約期間満了日までの期間に、お客さまにスマモル賃貸プランの適用がなくなった場合は、当社は解約金をいただきません。',
          type: ComponentDescriptionType.Normal
        }
      ],
      [
        {
          text:
            '●スマモル賃貸プランを料金適用開始の日が属する月をひと月目として24か月目の月の末日までに解約した場合、当該契約が満了する予定であった日まではスマモル賃貸サービスを利用いただけます。当該契約が満了する予定であった日以降のスマートロックのご利用には、別途費用が発生する場合があります。詳細は管理会社等にお問い合わせください。',
          type: ComponentDescriptionType.Normal
        }
      ],
      [
        {
          text: '',
          type: ComponentDescriptionType.Normal
        }
      ],
      [
        {
          text: '７．お申込みについて',
          type: ComponentDescriptionType.Normal
        }
      ],
      [
        {
          text:
            '●当社が適当でないと判断した場合、その他やむを得ない事情がある場合には、申込みを承諾できない場合があります。',
          type: ComponentDescriptionType.Normal
        }
      ],
      [
        {
          text: '',
          type: ComponentDescriptionType.Normal
        }
      ],
      [
        {
          text: '８．当社によるプラン変更・廃止について',
          type: ComponentDescriptionType.Normal
        }
      ],
      [
        {
          text: '●スマモル賃貸プランの内容を変更・廃止する場合があります。あらかじめご了承ください。',
          type: ComponentDescriptionType.Normal
        }
      ],
      [
        {
          text: '',
          type: ComponentDescriptionType.Normal
        }
      ],
      [
        {
          text: '９．その他',
          type: ComponentDescriptionType.Normal
        }
      ],
      [
        {
          text:
            '●付帯サービスをお客さまに提供するにあたり、お客さまの名義、需要場所（供給地点特定番号を含みます。）、電話番号、その他の需給契約に係る事項ならびに付帯するサービス契約に係る事項について、株式会社ビットキーおよび大阪ガスセキュリティサービス株式会社に情報を提供することならびに株式会社ビットキーおよび大阪ガスセキュリティサービス株式会社から情報の提供を受けることがあります。',
          type: ComponentDescriptionType.Normal
        }
      ],
      [
        {
          text:
            '●現在ご契約の料金メニューによっては、スマモル賃貸プランへの変更により、デメリットが生じる場合があります。',
          type: ComponentDescriptionType.Normal
        }
      ],
      [
        {
          text: '●お引越しされる場合は、当社へ電気の需給契約の廃止のご連絡をしていただく必要がございます。',
          type: ComponentDescriptionType.Normal
        }
      ],
      [
        {
          text:
            '●詳細の運用等については、電気基本契約要綱（低圧）および電気個別要綱（スマモル賃貸プランB）、電気個別要綱（スマモル賃貸プランAE）に定めるとおりといたします。',
          type: ComponentDescriptionType.Normal
        }
      ],
      [
        {
          text: '●その他の事項については、「電気需給契約に関する重要事項説明書」をご確認ください。',
          type: ComponentDescriptionType.Normal
        }
      ]
    ],
    company: [
      [
        {
          text: '西部ガス株式会社',
          type: ComponentDescriptionType.Title
        }
      ],
      [
        {
          text: '(小売電気事業者登録番号 XXXXXX)',
          type: ComponentDescriptionType.Normal
        }
      ],
      [
        {
          text: '福岡県福岡市博多区千代１－１７－１',
          type: ComponentDescriptionType.Normal
        }
      ],
      [
        {
          text: '',
          type: ComponentDescriptionType.Title
        }
      ],
      [
        {
          text: 'お問い合わせ先 ０５７０－０００－３１２',
          type: ComponentDescriptionType.Title
        }
      ],
      [
        {
          text: '受付時間　平日　9時〜19時 ・ 土日、祝日、1/2、1/3 9時〜17時',
          type: ComponentDescriptionType.Normal
        }
      ]
    ]
  };
};

export const confirmationText: {
  contents: DescriptionElement[][];
  isDisplay?: ComponentElementCondition[];
}[] = [
  {
    contents: [
      [
        {
          text:
            '上記でご入力いただいた情報は、「優待・割引サービス」「駆けつけサービス」で利用されます。また、お客さまが入居契約した物件の管理会社もご入力いただいた情報を利用します。',
          type: ComponentDescriptionType.Normal
        }
      ]
    ]
  },
  {
    contents: [
      // [
      //   {
      //     text: '・',
      //     type: ComponentDescriptionType.Normal
      //   },
      //   {
      //     text: '電気需給約款（低圧）',
      //     type: ComponentDescriptionType.InternalLink,
      //     path: '/static/dummy.pdf'
      //   }
      // ],
      // [
      //   {
      //     text: '・',
      //     type: ComponentDescriptionType.Normal
      //   },
      //   {
      //     text: '電気料金プラン定義書【スマモル賃貸プラン】',
      //     type: ComponentDescriptionType.InternalLink,
      //     path: '/static/dummy.pdf'
      //   }
      // ],
      // [
      //   {
      //     text: '・',
      //     type: ComponentDescriptionType.Normal
      //   },
      //   {
      //     text: '重要事項説明書',
      //     type: ComponentDescriptionType.InternalLink,
      //     path: '/important-term'
      //   }
      // ],
      [
        {
          text: '・',
          type: ComponentDescriptionType.Normal
        },
        {
          text: '駆けつけサービス契約約款',
          type: ComponentDescriptionType.InternalLink,
          path: '/static/security_service_term.pdf'
        }
      ],
      [
        {
          text: '・',
          type: ComponentDescriptionType.Normal
        },
        {
          text: '優待・割引サービス利用規約',
          type: ComponentDescriptionType.InternalLink,
          path: '/static/benefit_one_term.pdf'
        }
      ],
      [
        {
          text: 'にご同意上、申込み内容のご確認へお進みください',
          type: ComponentDescriptionType.Normal
        }
      ]
    ],
    isDisplay: useElectronicCondition
  },
  {
    contents: [
      [
        {
          text: '・',
          type: ComponentDescriptionType.Normal
        },
        {
          text: '駆けつけサービス契約約款',
          type: ComponentDescriptionType.InternalLink,
          path: '/static/security_service_term.pdf'
        }
      ],
      [
        {
          text: '・',
          type: ComponentDescriptionType.Normal
        },
        {
          text: '優待・割引サービス利用規約',
          type: ComponentDescriptionType.InternalLink,
          path: '/static/benefit_one_term.pdf'
        }
      ],
      [
        {
          text: 'にご同意上、申込み内容のご確認へお進みください',
          type: ComponentDescriptionType.Normal
        }
      ]
    ],
    isDisplay: notUseElectronicCondition
  }
];
