import React, { useMemo } from 'react';
import { Container } from '@material-ui/core';
import { Path } from '../../constants/path';
import BaseStepper from '../../components/BaseStepper';
import { ApplyHeading, AccountCaption, AccountBottomGuide } from '../../components/Label';
import SignInForm from '../../container/signInForm';
import { ChangeAccountTypeButton } from '../../components/LinkButton';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { DesktopBackground } from '../../components/atoms/DesktopBackground';

const useStyles = makeStyles(() =>
  createStyles({
    container: {
      padding: '0 24px',
      marginBottom: '48px',
      '@media (min-width:768px)': {
        padding: '0 32px 32px',
        '& h1': {
          textAlign: 'center'
        },
        '&>p': {
          textAlign: 'center',
          fontSize: 14
        }
      }
    }
  })
);

interface Props {
  hasElectricContract?: boolean;
}

const SigninScreen: React.FC<Props> = ({ hasElectricContract }) => {
  const styles = useStyles({});
  const planName = useMemo(() => (hasElectricContract ? 'スマモル賃貸プラン' : 'スマモル賃貸サービス'), [
    hasElectricContract
  ]);
  return (
    <DesktopBackground>
      <BaseStepper step={0} />
      <Container className={styles.container}>
        <ApplyHeading text={'Bitkeyアカウント連携'} />
        <AccountCaption text={`${planName}のご利用には、Bitkey社の提供するbitkeyアカウントが必要です。`} />
        <SignInForm />

        <AccountBottomGuide text={'Bitkeyアカウントをお持ちでない方は'} />
        <ChangeAccountTypeButton path={Path.account.signup} text={'アカウント作成'} />
      </Container>
    </DesktopBackground>
  );
};

export default SigninScreen;
